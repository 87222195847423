import { DatePicker, Form } from "antd";

import { ConstraintRecordProps } from "../columnInfo";

const DateInput = ({ constraintName, constraintId }: ConstraintRecordProps) => {
    const displayStartDateName = [
        constraintName,
        `constraint_id_${constraintId}`,
        "display_start_date",
    ];
    const includeNullName = [
        constraintName,
        `constraint_id_${constraintId}`,
        "include_null",
    ];

    return (
        <Form.Item
            style={{ margin: 0 }}
            name={displayStartDateName}
            preserve={false}
            rules={[
                ({ getFieldValue }) => ({
                    validator: (_, value) => {
                        const includeNullValue = Boolean(
                            getFieldValue(includeNullName)
                        );

                        if (!value && !includeNullValue) {
                            return Promise.reject("Please enter a date");
                        } else {
                            return Promise.resolve();
                        }
                    },
                }),
            ]}
        >
            <DatePicker
                style={{ width: "100%" }}
                data-testid="date"
                format={"YYYY-MM-DD"}
                showTime={false}
            />
        </Form.Item>
    );
};

export default DateInput;
