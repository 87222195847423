import { CopyOutlined, ReloadOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Modal, Typography, Form, Button, Checkbox, Radio, Space, Tag } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { SearchPlanFormIntelligenceScopeDetail } from "reactQuery/hooks/apiTypes";
import {
    useGetSearchPlanOverview,
    useConstraintCopyToSearchPlan,
} from "reactQuery/hooks/useSearchPlanForm";
import { searchPlanFormKeys } from "reactQuery/keys";
import { useSnackStore, useModalStore, ModalNames } from "stores/zustandStore";

import { intelligenceScopeHeader } from "../headerHelper";
import { intelligenceScopesSorter } from "../sortHelper";

const { Text } = Typography;

export const constraintsCopiedSuccessfullyMessage = "Constraints copied successfully";
export const constraintsCopyErrorMessage = "Error copying constraints";

const CopyConstraintsModal = () => {
    const { search_plan_id: searchPlanId } = useParams();
    const searchPlanOverviewQuery = useGetSearchPlanOverview(searchPlanId);

    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);

    const setModalHidden = useModalStore((state) => state.setModalHidden);

    const openModal = useModalStore((state) => state.openModal);
    const modalInitialValues = useModalStore((state) => state.modalInitialValues);

    useEffect(() => {
        if (openModal === ModalNames.CopyConstraints) {
            searchPlanOverviewQuery.refetch();
        }
    }, [openModal]);

    const queryClient = useQueryClient();
    const copyConstraintsQuery = useConstraintCopyToSearchPlan(searchPlanId);

    const [copyConstraintsModalForm] = Form.useForm();
    const copyConstraintsModalFormId = "copy-constraints-modal";

    const intelligenceScopeForm = modalInitialValues.form;

    const onFinish = () => {
        const selectedIntelligenceScopeIds: number[] =
            copyConstraintsModalForm.getFieldValue("intelligence_scopes");
        const method: string = copyConstraintsModalForm.getFieldValue("method");
        const setTrackedChanges = modalInitialValues.setTrackedChanges;

        copyConstraintsQuery.mutate(
            {
                method,
                selected_constraints: modalInitialValues.constraints,
                origin_intelligence_scope_id: modalInitialValues.intelligenceScopeId,
                selected_intelligence_scope_ids: selectedIntelligenceScopeIds,
            },
            {
                onSuccess: () => {
                    searchPlanOverviewQuery.data.intelligence_scopes.forEach(
                        ({ id }) => {
                            queryClient.invalidateQueries(
                                searchPlanFormKeys.intelligenceScopeDetail(id)
                            );
                        }
                    );
                    displaySuccessSnack({
                        message: constraintsCopiedSuccessfullyMessage,
                    });
                    resetCopyToggles();
                    resetAndCloseModal();
                    setTrackedChanges({});
                },
                onError: () => {
                    displayErrorSnack({
                        message: constraintsCopyErrorMessage,
                    });
                },
            }
        );
    };
    const resetCopyToggles = () => {
        // reset the copy constraint toggles
        const constraints =
            modalInitialValues.constraints as SearchPlanFormIntelligenceScopeDetail["all_constraints_to_copy"];

        Object.entries(constraints).forEach((constraint_array) => {
            let formFieldNames = constraint_array[1].map((id: number) => {
                return [constraint_array[0], `constraint_id_${id}`, "copy"];
            });
            intelligenceScopeForm.resetFields(formFieldNames);
        });
    };

    const resetAndCloseModal = () => {
        copyConstraintsModalForm.resetFields();
        setModalHidden();
    };

    const [checkBoxOptions, setCheckBoxOptions] = useState([]);
    useEffect(() => {
        if (searchPlanOverviewQuery.isSuccess) {
            setCheckBoxOptions(
                searchPlanOverviewQuery.data.intelligence_scopes
                    .sort(intelligenceScopesSorter)
                    .map(({ id, industry, indicator, rank }, index) => {
                        const header = intelligenceScopeHeader(
                            industry,
                            indicator,
                            rank,
                            index
                        );
                        return {
                            label: header,
                            intelligenceScopeId: id,
                        };
                    })
            );
        }
    }, [searchPlanOverviewQuery.isSuccess, searchPlanOverviewQuery.data]);

    return (
        <Modal
            open={openModal === ModalNames.CopyConstraints}
            centered
            forceRender
            destroyOnClose={true}
            bodyStyle={{ padding: 0 }}
            onCancel={resetAndCloseModal}
            title="Copy Selected Constraints To Intelligence Scopes"
            footer={
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                    }}
                >
                    <div>
                        <Button
                            key="submit"
                            form={copyConstraintsModalFormId}
                            type="default"
                            onClick={resetAndCloseModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            key="link"
                            type="primary"
                            htmlType="submit"
                            style={{ marginLeft: "10px" }}
                            form={copyConstraintsModalFormId}
                            disabled={copyConstraintsQuery.isLoading}
                            loading={copyConstraintsQuery.isLoading}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            }
        >
            <Form
                id={copyConstraintsModalFormId}
                form={copyConstraintsModalForm}
                name={copyConstraintsModalFormId}
                layout="vertical"
                preserve={false}
                onFinish={onFinish}
                initialValues={{
                    intelligence_scopes: [],
                    method: "replace",
                }}
            >
                <Form.Item
                    name="intelligence_scopes"
                    label="Intelligence Scopes"
                    required={true}
                    preserve={false}
                    style={{ margin: "20px" }}
                    rules={[
                        () => ({
                            validator: (_, value) => {
                                if (!value.length) {
                                    return Promise.reject(
                                        "Please select at least one intelligence scope"
                                    );
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <Checkbox.Group>
                        <Space direction="vertical" size="middle">
                            {checkBoxOptions.map(({ label, intelligenceScopeId }) => (
                                <Checkbox
                                    key={intelligenceScopeId}
                                    value={intelligenceScopeId}
                                >
                                    {label}
                                </Checkbox>
                            ))}
                        </Space>
                    </Checkbox.Group>
                </Form.Item>

                <Form.Item
                    name="method"
                    rules={[{ required: true, message: "Please choose a method" }]}
                    style={{ margin: "20px" }}
                    label="Method"
                >
                    <Radio.Group>
                        <Space direction="vertical">
                            <Radio value="replace">
                                <Tag icon={<ReloadOutlined />} color="volcano">
                                    Replace
                                </Tag>
                                <Text type="secondary">
                                    Replace existing constraints in selected
                                    intelligence scopes with selected ones
                                </Text>
                            </Radio>
                            <Radio value="copy">
                                <Tag icon={<CopyOutlined />} color="cyan">
                                    Copy
                                </Tag>
                                <Text type="secondary">
                                    Copy selected constraints to selected intelligence
                                    scopes
                                </Text>
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CopyConstraintsModal;
