import {
    OrderedListOutlined,
    BuildOutlined,
    TableOutlined,
    AreaChartOutlined,
    FileSearchOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import { IntelligenceReportsAllInsightsPathname } from "pages/IntelligenceReports/IntelligenceReportsAllInsights";
import { IntelligenceReportsAllReportsPathname } from "pages/IntelligenceReports/IntelligenceReportsAllReports";
import { IntelligenceReportsDashboardPathname } from "pages/IntelligenceReports/IntelligenceReportsDashboard";
import { Product, ProductTypeEnum } from "reactQuery/hooks/apiTypes";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import { DocumentCompareUploadPathname } from "routes/DocumentCompare";
import { IndicatorFeedsAllFeedsPathname } from "routes/IndicatorFeeds";
import {
    AllFeedbackPathname,
    AllIndicatorsPathname,
    AllInsightsPathname,
    AllOwnersPathname,
    AssignedInsightsPathname,
    IntelligenceFeedsDashboardPathname,
    MyInsightsPathname,
    PriorityInsightsPathname,
} from "routes/IntelligenceFeeds";
import {
    LWAllInsightsPathname,
    LWAllResultsPathname,
    LWMyActionedPathname,
    LWMyQueuePathname,
    LWReadyForQCPathname,
} from "routes/LW";
import {
    hasIntelligenceFeeds,
    userHasAccessToAssignedInsights,
    userHasAccessToMyInsights,
} from "utils/redirect";
import { useGetBasePath } from "utils/useGetBasePath";

const intelligenceFeedsDrawer = (product: Product) => {
    return {
        key: "intelligence_feeds",
        icon: <OrderedListOutlined />,
        label: product.name,
        children: [],
    };
};
const activityListsDrawer = () => {
    return {
        key: "intelligence_feeds_activity_lists",
        icon: <OrderedListOutlined />,
        label: "Activity Lists",
        children: [],
    };
};
const allFeedsDrawer = () => {
    return {
        key: "intelligence_feeds_all_feeds",
        icon: <OrderedListOutlined />,
        label: "All Feeds",
        children: [],
    };
};
const intelligenceFeedsMyInsights = (teamId: number) => {
    const pathname = MyInsightsPathname;

    return {
        key: pathname,
        icon: <TableOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>My Insights</Link>,
    };
};
const intelligenceFeedsAssignedInsights = (teamId: number) => {
    const pathname = AssignedInsightsPathname;

    return {
        key: pathname,
        icon: <TableOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>Assigned Insights</Link>,
    };
};
const intelligenceFeedsAllFeedback = (teamId: number) => {
    const pathname = AllFeedbackPathname;

    return {
        key: pathname,
        icon: <TableOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>All Feedback</Link>,
    };
};
const intelligenceFeedsPriorityInsights = (teamId: number) => {
    const pathname = PriorityInsightsPathname;

    return {
        key: pathname,
        icon: <TableOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>Priority Insights</Link>,
    };
};
const intelligenceFeedsAllInsights = (teamId: number) => {
    const pathname = AllInsightsPathname;

    return {
        key: pathname,
        icon: <TableOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>All Insights</Link>,
    };
};
const intelligenceFeedsAllIndicators = (teamId: number) => {
    const pathname = AllIndicatorsPathname;

    return {
        key: pathname,
        icon: <TableOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>All Indicators</Link>,
    };
};
const intelligenceFeedsAllOwners = (teamId: number) => {
    const pathname = AllOwnersPathname;

    return {
        key: pathname,
        icon: <TableOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>All Owners</Link>,
    };
};

const intelligenceFeedsDashboard = (teamId: number) => {
    const pathname = IntelligenceFeedsDashboardPathname;

    return {
        key: pathname,
        icon: <TableOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>Dashboard</Link>,
    };
};

const intelligenceReportsDrawer = (product: Product) => {
    return {
        key: "ir",
        icon: <OrderedListOutlined />,
        label: product.name,
        children: [],
    };
};

const intelligenceReportsAllInsights = (teamId: number) => {
    const pathname = IntelligenceReportsAllInsightsPathname;

    return {
        key: pathname,
        icon: <TableOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>All Insights</Link>,
    };
};
const intelligenceReportsAllReports = (teamId: number) => {
    const pathname = IntelligenceReportsAllReportsPathname;

    return {
        key: pathname,
        icon: <TableOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>All Reports</Link>,
    };
};
const intelligenceReportsDashboard = (teamId: number) => {
    const pathname = IntelligenceReportsDashboardPathname;

    return {
        key: pathname,
        icon: <TableOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>Dashboard</Link>,
    };
};

const indicatorFeedsAllFeeds = (product: Product, teamId: number) => {
    const pathname = IndicatorFeedsAllFeedsPathname;

    return {
        key: pathname,
        icon: <BuildOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>{product.name}</Link>,
    };
};

const dashboardProduct = (product: Product, teamId: number) => {
    const pathname = product.path;

    return {
        key: pathname,
        icon: <AreaChartOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>{product.name}</Link>,
    };
};

const documentCompareProduct = (product: Product, teamId: number) => {
    const pathname = DocumentCompareUploadPathname;

    return {
        key: pathname,
        icon: <FileSearchOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>{product.name}</Link>,
    };
};

const LWFeedsDrawer = (product) => {
    return {
        key: "lw",
        icon: <OrderedListOutlined />,
        label: product.name,
        children: [],
    };
};

const LWAllInsights = (teamId: number) => {
    const pathname = LWAllInsightsPathname;

    return {
        key: pathname,
        icon: <TableOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>All Insights</Link>,
    };
};

const LWAllResults = (teamId: number) => {
    const pathname = LWAllResultsPathname;

    return {
        key: pathname,
        icon: <TableOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>All Results</Link>,
    };
};

const LWMyActioned = (teamId: number) => {
    const pathname = LWMyActionedPathname;

    return {
        key: pathname,
        icon: <TableOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>My Actioned</Link>,
    };
};

const LWMyQueue = (teamId: number) => {
    const pathname = LWMyQueuePathname;

    return {
        key: pathname,
        icon: <TableOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>My Queue</Link>,
    };
};

const LWReadyForQC = (teamId: number) => {
    const pathname = LWReadyForQCPathname;

    return {
        key: pathname,
        icon: <TableOutlined />,
        label: <Link to={`${pathname}?team_id=${teamId}`}>Ready For QC</Link>,
    };
};

const ProductMenu = ({
    productOfferings,
    selectedTeamId,
}: {
    productOfferings: Product[];
    selectedTeamId: number;
}) => {
    const getWhoamiQuery = useGetWhoami();
    const isDataCore = getWhoamiQuery.data?.user.is_datacore;
    const isStaff = getWhoamiQuery.data?.user.is_staff;
    const basepath = useGetBasePath();

    return (
        <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[basepath]}
            defaultOpenKeys={[
                "ir",
                "intelligence_feeds",
                "intelligence_feeds_activity_lists",
                "intelligence_feeds_all_feeds",
                "lw",
            ]}
            items={mapProductOfferingsToMenuItems(
                productOfferings,
                selectedTeamId,
                isDataCore,
                isStaff
            )}
        />
    );
};

export const mapProductOfferingsToMenuItems = (
    productOfferings: Product[],
    selectedTeamId: number,
    isDataCore: boolean,
    isStaff: boolean
) => {
    const menuItems = [];

    // when a team has intelligence feeds, we shouldn't show the IR / IF menu items
    const teamHasIntelligenceFeeds = hasIntelligenceFeeds(selectedTeamId);

    // If a user is DataCore, they should see Labelling Workflow products
    if (isStaff || isDataCore) {
        const LWDrawerItems = {
            ...LWFeedsDrawer({ name: "Labelling Workflow" }),
            children: [
                LWAllResults(selectedTeamId),
                LWMyQueue(selectedTeamId),
                LWMyActioned(selectedTeamId),
                LWReadyForQC(selectedTeamId),
                LWAllInsights(selectedTeamId),
            ],
        };
        menuItems.push(LWDrawerItems);
    }

    /* this function preserves the visual ordering of product types defined by their rank - see backend serializer */
    productOfferings.forEach((product) => {
        if (product.product_type === ProductTypeEnum.INTELLIGENCE_FEEDS) {
            const intelligenceFeedsDrawerItems = {
                ...intelligenceFeedsDrawer(product),
                children: [],
            };
            const activityListsDrawerItems = { ...activityListsDrawer(), children: [] };
            const allFeedsDrawerItems = { ...allFeedsDrawer(), children: [] };

            activityListsDrawerItems.children.push(
                ...[
                    ...(userHasAccessToMyInsights()
                        ? [intelligenceFeedsMyInsights(selectedTeamId)]
                        : []),
                    ...(userHasAccessToAssignedInsights()
                        ? [intelligenceFeedsAssignedInsights(selectedTeamId)]
                        : []),
                    intelligenceFeedsAllFeedback(selectedTeamId),
                ]
            );
            allFeedsDrawerItems.children.push(
                ...[
                    intelligenceFeedsPriorityInsights(selectedTeamId),
                    intelligenceFeedsAllInsights(selectedTeamId),
                    intelligenceFeedsAllIndicators(selectedTeamId),
                    intelligenceFeedsAllOwners(selectedTeamId),
                ]
            );

            intelligenceFeedsDrawerItems.children.push(
                ...[
                    activityListsDrawerItems,
                    allFeedsDrawerItems,
                    intelligenceFeedsDashboard(selectedTeamId),
                ]
            );
            menuItems.push(intelligenceFeedsDrawerItems);
        }
        if (
            product.product_type === ProductTypeEnum.INTELLIGENCE_REPORTS &&
            !teamHasIntelligenceFeeds
        ) {
            const irDrawer = { ...intelligenceReportsDrawer(product), children: [] };
            irDrawer.children.push(intelligenceReportsAllInsights(selectedTeamId));
            irDrawer.children.push(intelligenceReportsAllReports(selectedTeamId));
            irDrawer.children.push(intelligenceReportsDashboard(selectedTeamId));
            menuItems.push(irDrawer);
        }
        if (
            product.product_type === ProductTypeEnum.INDICATOR_FEEDS &&
            !teamHasIntelligenceFeeds
        ) {
            menuItems.push(indicatorFeedsAllFeeds(product, selectedTeamId));
        }

        if (product.product_type === ProductTypeEnum.DASHBOARD) {
            menuItems.push(dashboardProduct(product, selectedTeamId));
        }

        if (product.product_type === ProductTypeEnum.DOCUMENT_COMPARE) {
            menuItems.push(documentCompareProduct(product, selectedTeamId));
        }
    });

    return menuItems;
};

export default ProductMenu;
