import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryResult,
    useQueryClient,
} from "@tanstack/react-query";

import { nautilusGetRequest, nautilusPatchRequest } from "network";
import {
    insightsKeys,
    insightStatusKeys,
    tableDataKeys,
    viewDetailsKeys,
} from "reactQuery/keys";
import { queryParamBuilder } from "utils/useManagedQueryParams";

import { DeepInsight, PatchedInsightWrite } from "./apiTypes";

export const useGetSingleInsight = (
    insightId: number,
    enabled: boolean = true,
    teamId?: number
): UseQueryResult<DeepInsight> => {
    const queryKey = insightsKeys.single(insightId);
    const params = teamId ? `?team_id=${teamId}` : undefined;
    return useQuery({
        queryKey,
        queryFn: async (): Promise<DeepInsight> => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/insights/${insightId}`,
                params
            );
            return data;
        },
        enabled,
    });
};

interface InsightPatchQueryPayloadType {
    insightId: number;
    payload: PatchedInsightWrite;
}

async function patchInsight({
    payload,
    insightId,
}: InsightPatchQueryPayloadType): Promise<DeepInsight> {
    const { data } = await nautilusPatchRequest(
        `/api/target_reports/insights/${insightId}`,
        payload
    );
    return data;
}

export function usePatchInsight(): Partial<UseMutationResult<DeepInsight>> {
    return useMutation((insightPatchQueryPayload: InsightPatchQueryPayloadType) => {
        return patchInsight(insightPatchQueryPayload);
    });
}

export const useGetInsightStatuses = (
    enabled: boolean = true
): Partial<UseQueryResult<any>> => {
    const queryKey = insightStatusKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<any> => {
            const { data } = await nautilusGetRequest(
                `/api/target_reports/insight_statuses/`
            );
            return data;
        },
        enabled,
    });
};

async function updateInsights({
    insightIds,
    feedType,
    queryParams,
    queryClient,
    delivery_status = null,
}) {
    const tableQueryKey = tableDataKeys.createKey(feedType, queryParams);
    const detailsQueryKey = viewDetailsKeys.all();

    await queryClient.cancelQueries(tableQueryKey);

    const paginatedInsights = queryClient.getQueryData(tableQueryKey);
    const currentPaginatedInsights = paginatedInsights as any;

    const currentInsights = currentPaginatedInsights.results;
    const currentDetailsData = queryClient.getQueriesData(detailsQueryKey) as any;

    const newInsights = currentInsights.map((insight) =>
        insightIds.includes(insight.id)
            ? {
                  ...insight,
                  delivery_status: delivery_status || insight.delivery_status,
              }
            : insight
    );

    // Optimisitcally update table data
    const newPaginatedIPLResults = {
        ...currentPaginatedInsights,
        results: newInsights,
    };
    queryClient.setQueryData(tableQueryKey, newPaginatedIPLResults);

    // Optimistically update view details data
    if (currentDetailsData) {
        currentDetailsData.map((currentDetailsDataItem) => {
            const queryKey = currentDetailsDataItem[0];
            const detailsItem = currentDetailsDataItem[1];
            if (insightIds.includes(detailsItem?.insight?.id)) {
                queryClient.setQueryData(queryKey, {
                    ...detailsItem,
                    insight: {
                        ...detailsItem.insight,
                        delivery_status:
                            delivery_status || detailsItem.insight.delivery_status,
                    },
                });
            }
        });
    }

    return {
        currentPaginatedInsights,
        currentDetailsData,
    };
}

async function resetInsights({ queryClient, feedType, queryParams, context }) {
    // Revert table data
    queryClient.setQueryData(
        tableDataKeys.createKey(feedType, queryParams),
        context.currentPaginatedInsights
    );

    // Revert view details data
    if (context.currentDetailsData) {
        context.currentDetailsData.map((currentDetailsDataItem) => {
            const queryKey = currentDetailsDataItem[0];
            const detailsItem = currentDetailsDataItem[1];
            queryClient.setQueryData(queryKey, detailsItem);
        });
    }
}

export function useUpdateInsight({
    feedType,
    defaultPageSize,
}: {
    feedType: string;
    defaultPageSize: number;
}) {
    const queryParams = queryParamBuilder({
        includeBaseParams: true,
        includeDefaultPagination: true,
        defaultPageSize,
    });
    const queryClient = useQueryClient();

    return useMutation(
        (requestData) => {
            return patchInsight({
                payload: { delivery_status: requestData.delivery_status },
                insightId: requestData.id,
            });
        },
        {
            onMutate: async (requestData: any) => {
                let insightIds = [requestData.id];

                const { currentPaginatedInsights, currentDetailsData } =
                    await updateInsights({
                        insightIds,
                        feedType,
                        queryParams,
                        queryClient,
                        delivery_status: requestData.delivery_status,
                    });

                return {
                    currentPaginatedInsights,
                    insightIds,
                    currentDetailsData,
                };
            },
            onError: (_err, _, context) => {
                resetInsights({ queryClient, feedType, queryParams, context });
            },
        }
    );
}
