import { useQueryClient } from "@tanstack/react-query";
import { Button, Form, Collapse, Space, Spin, Table } from "antd";
import merge from "deepmerge";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
    ConstraintNameEnum,
    SearchPlanFormIntelligenceScopeListItem,
} from "reactQuery/hooks/apiTypes";
import {
    useDeleteIntelligenceScope,
    useGetIntelligenceScopeDetail,
    useGetSearchPlanOverview,
    usePatchIntelligenceScope,
} from "reactQuery/hooks/useSearchPlanForm";
import { searchPlanFormKeys } from "reactQuery/keys";
import { ModalNames, useModalStore, useSnackStore } from "stores/zustandStore";

import {
    additionalNotesColumn,
    deleteInteligenceScopeColumn,
    indicatorCascaderColumn,
    rankColumn,
} from "../columnInfo";
import ConstraintCollapse from "../ConstraintCollapse";
import { setFormValues } from "../formHelper";

const { Panel } = Collapse;

export interface IntelligenceScopeFormProps {
    intelligenceScope: SearchPlanFormIntelligenceScopeListItem;
    deleteTemporaryIntelligenceScope: (id: number) => void;
    setDefaultActiveKey: (key: number) => void;
}

export const intelligenceScopeSuccessfullyUpdatedMessage =
    "Intelligence Scope successfully updated";
export const intelligenceScopeSuccessfullyDeletedMessage =
    "Intelligence Scope deleted successfully";
export const intelligenceScopeDeleteErrorMessage = "Error deleting Intelligence Scope";
export const intelligenceScopePatchErrorMessage = "Error updating Intelligence Scope";

const IntelligenceScopeForm = ({
    intelligenceScope,
    deleteTemporaryIntelligenceScope,
    setDefaultActiveKey,
}: IntelligenceScopeFormProps) => {
    const { search_plan_id: searchPlanId } = useParams();

    const [form] = Form.useForm();
    const formId = `intelligence-scopes-form-${intelligenceScope.id}`;

    const queryClient = useQueryClient();
    const searchPlanOverviewQuery = useGetSearchPlanOverview(searchPlanId);
    const intelligenceScopeDetailQuery = useGetIntelligenceScopeDetail(
        intelligenceScope.id
    );
    const deleteIntelligenceScopeQuery = useDeleteIntelligenceScope(
        intelligenceScope.id
    );
    const patchIntelligenceScope = usePatchIntelligenceScope(intelligenceScope.id);

    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);

    const setModalVisible = useModalStore((state) => state.setModalVisible);

    const [trackedChanges, setTrackedChanges] = useState<any>({});
    const valuesChanged = (changed: any) => {
        // Merge changed values with trackedChanges, ensuring that any arrays (even empty) in the changed values
        // replace the arrays in trackedChanges. This functionality is tested with the following important test:
        // "User can clear multi select options and an empty array will be submitted to the backend"
        const deepMerge = merge(trackedChanges, changed, {
            arrayMerge: (_destArray, srcArray) => srcArray,
        });
        setTrackedChanges(deepMerge);
    };

    const deleteIntelligenceScope = () => {
        if (intelligenceScope.id < 0) {
            deleteTemporaryIntelligenceScope(intelligenceScope.id);
            displaySuccessSnack({
                message: "Intelligence Scope deleted successfully",
            });
            return;
        }
        deleteIntelligenceScopeQuery.mutate(
            {},
            {
                onSuccess: () => {
                    searchPlanOverviewQuery.refetch();
                    displaySuccessSnack({
                        message: "Intelligence Scope deleted successfully",
                    });
                },
                onError: () => {
                    displayErrorSnack({
                        message: "Error deleting Intelligence Scope",
                    });
                },
            }
        );
    };

    const performMutate = () => {
        if (intelligenceScope.id < 0) {
            trackedChanges.search_plan_id = searchPlanId;
        }

        // Normalize display_start_date values from moment object to string
        Object.keys(trackedChanges?.display_start_date ?? {}).forEach((key) => {
            let display_start_date =
                trackedChanges.display_start_date[key]?.display_start_date;
            if (display_start_date) {
                const displayStartDateWithLocale =
                    moment(display_start_date).locale("en");
                trackedChanges.display_start_date[key].display_start_date =
                    displayStartDateWithLocale.format("YYYY-MM-DD");
            }
        });

        patchIntelligenceScope.mutate(
            { ...trackedChanges },
            {
                onSuccess: (response) => {
                    setDefaultActiveKey(response.id);
                    queryClient.setQueryData(
                        searchPlanFormKeys.intelligenceScopeDetail(
                            intelligenceScope.id
                        ),
                        response
                    );
                    searchPlanOverviewQuery.refetch();

                    displaySuccessSnack({
                        message: intelligenceScopeSuccessfullyUpdatedMessage,
                    });

                    if (Object.keys(response.all_constraints_to_copy).length > 0) {
                        setModalVisible(ModalNames.CopyConstraints, {
                            constraints: response.all_constraints_to_copy,
                            form,
                            setTrackedChanges,
                            intelligenceScopeId: response.id,
                        });
                    } else {
                        setTrackedChanges({});
                    }
                },
                onError: (e) => {
                    displayErrorSnack({
                        message: intelligenceScopePatchErrorMessage,
                    });
                },
            }
        );
    };

    const [deletePopconfirmOpen, setDeletePopconfirmOpen] = useState(false);
    const deleteIntelScopeColumn = deleteInteligenceScopeColumn(
        deletePopconfirmOpen,
        setDeletePopconfirmOpen,
        deleteIntelligenceScope
    );

    useEffect(() => {
        if (intelligenceScopeDetailQuery.isSuccess) {
            setFormValues(form, intelligenceScopeDetailQuery.data);
        }
    }, [intelligenceScopeDetailQuery.isSuccess, intelligenceScopeDetailQuery.data]);

    if (intelligenceScopeDetailQuery.isLoading) {
        return <Spin data-testid="spinner" />;
    }

    const columns = [
        indicatorCascaderColumn,
        rankColumn,
        additionalNotesColumn,
        deleteIntelScopeColumn,
    ];

    return (
        <div style={{ marginTop: "-16px" }}>
            <Form
                form={form}
                id={formId}
                name={formId}
                onFinish={performMutate}
                onFinishFailed={(error) => {
                    displayErrorSnack({
                        message:
                            error.errorFields[0]?.errors?.[0] ??
                            "Please fill out all required fields",
                    });
                }}
                onValuesChange={valuesChanged}
                layout="vertical"
                scrollToFirstError={true}
            >
                <Space direction="vertical" size="large" style={{ width: "100%" }}>
                    <Table
                        dataSource={[{ key: formId }]}
                        columns={columns}
                        size="small"
                        pagination={false}
                    />

                    <Space direction="vertical" size="large" style={{ width: "100%" }}>
                        <Collapse>
                            <Panel header="Filters" key="filters">
                                <Space
                                    direction="vertical"
                                    size="large"
                                    style={{ width: "100%" }}
                                >
                                    {[
                                        ConstraintNameEnum.Countries,
                                        ConstraintNameEnum.States,
                                        ConstraintNameEnum.Counties,
                                        ConstraintNameEnum.Owners,
                                        ConstraintNameEnum.OwnerTypes,
                                        ConstraintNameEnum.Population,
                                        ConstraintNameEnum.InitiativeTypes,
                                        ConstraintNameEnum.TimingStatuses,
                                        ConstraintNameEnum.IndicatorTags,
                                        ConstraintNameEnum.ProjectBudget,
                                        ConstraintNameEnum.SourceDocumentDate,
                                        ConstraintNameEnum.DesignFlowMgd,
                                        ConstraintNameEnum.DisplayStartDate,
                                    ].map((constraintName) => (
                                        <ConstraintCollapse
                                            key={constraintName}
                                            intelligenceScopeId={intelligenceScope.id}
                                            constraintName={constraintName}
                                            setTrackedChanges={setTrackedChanges}
                                            trackedChanges={trackedChanges}
                                        />
                                    ))}
                                </Space>
                            </Panel>
                        </Collapse>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "end",
                            }}
                        >
                            <Button
                                key="link"
                                type="primary"
                                htmlType="submit"
                                form={formId}
                                disabled={patchIntelligenceScope.isLoading}
                                loading={patchIntelligenceScope.isLoading}
                            >
                                Submit
                            </Button>
                        </div>
                    </Space>
                </Space>
            </Form>
        </div>
    );
};

export default IntelligenceScopeForm;
