import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { indicatorTagKeys } from "reactQuery/keys";

import { IndicatorTag } from "./apiTypes";

export const useGetIndicatorTagsByQuery = (
    query: string,
    indicatorId: number | undefined,
    enabled: boolean
): UseQueryResult<IndicatorTag[]> => {
    const queryKey = indicatorTagKeys.search(query, indicatorId);

    let params = `?limit=100&value=${query}`;
    if (indicatorId) {
        params += `&indicator_id=${indicatorId}`;
    }

    return useQuery({
        queryKey,
        queryFn: async (): Promise<IndicatorTag[]> =>
            (await nautilusGetRequest(`/api/target_reports/indicator_tags/`, params))
                .data?.results,
        enabled,
    });
};
