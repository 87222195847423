import { Form, Select } from "antd";

import { TimingStatuses } from "reactQuery/hooks/apiTypes";
import { useGetAllTimingStatuses } from "reactQuery/hooks/useTimingStatuses";

import { ConstraintRecordProps } from "../columnInfo";
import { validateMultiSelectWithIncludeNull } from "../validationHelper";

const TimingStatusesSelect = ({
    constraintName,
    constraintId,
}: ConstraintRecordProps) => {
    const timingStatusesQuery = useGetAllTimingStatuses();
    return (
        <Form.Item
            style={{ margin: 0 }}
            name={[constraintName, `constraint_id_${constraintId}`, "statuses"]}
            preserve={false}
            rules={[
                ({ getFieldValue }) =>
                    validateMultiSelectWithIncludeNull(
                        constraintName,
                        constraintId,
                        getFieldValue,
                        "Please select one or more timing statuses"
                    ),
            ]}
        >
            <Select
                placeholder="Select one or more timing statuses"
                showSearch
                style={{ width: "100%" }}
                mode="multiple"
                allowClear={true}
                optionFilterProp="children"
                filterOption={(input, option) => {
                    const timingStatus = option.title.toLowerCase();
                    return timingStatus.includes(input.toLowerCase());
                }}
            >
                {timingStatusesQuery.isLoading ? (
                    <Select.Option value="disabled" disabled>
                        Loading...
                    </Select.Option>
                ) : timingStatusesQuery.isSuccess ? (
                    timingStatusesQuery.data.map((timingStatus: TimingStatuses) => {
                        let title = `${timingStatus.status} | ${timingStatus.stage}`;
                        return (
                            <Select.Option
                                key={timingStatus.id}
                                value={timingStatus.id}
                                title={title}
                            >
                                {title}
                            </Select.Option>
                        );
                    })
                ) : (
                    <Select.Option value="disabled" disabled>
                        There was a problem loading timing status options
                    </Select.Option>
                )}
            </Select>
        </Form.Item>
    );
};

export default TimingStatusesSelect;
