import { Card } from "antd";

import { IPLResultWarning } from "components/molecules/ViewDetailsModal/warnings/IPLResultWarning";

export const InsightSummaryTemplate = ({ iplResult }: { iplResult: any }) => {
    const insight = iplResult.insight;
    return (
        <div>
            <Card
                title={
                    <a
                        target="_blank"
                        href={`/intrpt/opportunity?opportunity_id=${insight.opportunity?.id}&team_id=${insight.opportunity?.team_id}`}
                    >
                        Linked Insight {insight.id}
                    </a>
                }
            >
                <div style={{ fontSize: "17px" }}>
                    {insight.warning_labels ? (
                        <p>
                            {insight.warning_labels.map((warning) => (
                                <IPLResultWarning warning={warning} />
                            ))}
                        </p>
                    ) : null}
                    {insight.summary ? (
                        <p>
                            <p>
                                <b>Summary:</b>
                            </p>
                            <p>{insight.summary}</p>
                        </p>
                    ) : null}
                    {insight.source?.extract ? (
                        <p>
                            <p>
                                <b>Extract:</b>
                            </p>
                            <p>{insight.source.extract}</p>
                        </p>
                    ) : null}
                    {insight.opportunity_value ? (
                        <p>
                            <p>
                                <b>Opportunity Value:</b>
                            </p>
                            <p>{`$${insight.opportunity_value.toLocaleString()}`}</p>
                        </p>
                    ) : null}
                    {insight.indicators?.length > 0 ? (
                        <p>
                            <p>
                                <b>Indicators:</b>
                            </p>
                            <p>
                                {insight.indicators
                                    .map((indicator) => indicator.value)
                                    .join(", ")}
                            </p>
                        </p>
                    ) : null}
                    {insight.timing_status?.value ? (
                        <p>
                            <p>
                                <b>Timing Status:</b>
                            </p>
                            <p>{insight.timing_status.value}</p>
                        </p>
                    ) : null}
                    {insight.source?.url ? (
                        <p>
                            <p>
                                <b>Source URL:</b>
                            </p>
                            <p>
                                <a target="_blank" href={insight.source.url}>
                                    {insight.source.url}
                                </a>
                            </p>
                        </p>
                    ) : null}
                    {insight.source?.title ? (
                        <p>
                            <p>
                                <b>Source Title:</b>
                            </p>
                            <p>{insight.source.title}</p>
                        </p>
                    ) : null}
                    {insight.source?.date ? (
                        <p>
                            <p>
                                <b>Source Date:</b>
                            </p>
                            <p>{insight.source.date}</p>
                        </p>
                    ) : null}
                </div>
            </Card>
        </div>
    );
};
