// Let's try to have queryKey factories follow this model:
// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

export const actionsKeys = {
    all: () => ["actions"],
};

export const actionsTakenKeys = {
    all: () => ["actionsTaken"],
    filter: ({ opportunityId, teamId }: { opportunityId: number; teamId: number }) => [
        ...actionsTakenKeys.all(),
        "opportunityId",
        opportunityId,
        "teamId",
        teamId,
    ],
};

export const CountryKeys = {
    all: () => ["countries"],
};

export const countyKeys = {
    all: () => ["counties"],
    single: (id: number) => [...countyKeys.all(), id],
    search: (query: string) => [...countyKeys.all(), "search", query],
};

export const deliveryStatusKeys = {
    all: () => ["deliveryStatuses"],
};

export const documentCompareKeys = {
    single: (identifier: string, teamId: number) => [
        "documentCompare",
        identifier,
        "teamId",
        teamId,
    ],
};

export const emailGenerationInputFormKeys = {
    all: () => ["emailGenerationInputForm"],
    single: (team_id: number) => [...emailGenerationInputFormKeys.all(), team_id],
};

export const featureFlagsKeys = {
    all: () => ["featureFlags"],
};

export const indicatorGroupsKeys = {
    all: () => ["indicatorGroups"],
    active: () => ["indicatorGroupId"],
    single: (id: number) => [...indicatorGroupsKeys.all(), id],
    filter: ({ contentType, objectId }) => [
        ...indicatorGroupsKeys.all(),
        contentType,
        objectId,
    ],
};

export const indicatorCascaderKeys = {
    all: () => ["indicatorCascader"],
};

export const indicatorIndustryKeys = {
    all: () => ["indicatorIndustry"],
};

export const indicatorKeys = {
    all: () => ["indicators"],
    single: (id: number) => [...indicatorKeys.all(), id],
};

export const indicatorTagKeys = {
    all: () => ["indicatorTags"],
    single: (id: number) => [...indicatorTagKeys.all(), id],
    search: (query: string, indicatorId: number) => [
        ...indicatorTagKeys.all(),
        "search",
        query,
        indicatorId,
    ],
};

export const insightsKeys = {
    all: () => ["insights"],
    single: (id: number) => [...insightsKeys.all(), id],
};

export const initiativeTypeKeys = {
    all: () => ["initiativeTypes"],
};

export const insightStatusKeys = {
    all: () => ["insightStatusKeys"],
};

export const iplResultAssigneeActionedStatusChoiceKeys = {
    all: () => ["iplResultAssigneeActionedStatusChoices"],
};

export const iplResultDetailsAISummaryKeys = {
    all: () => ["iplResultDetailsAISummaries"],
    single: (iplResultId: number) => [
        ...iplResultDetailsAISummaryKeys.all(),
        iplResultId,
    ],
};

export const iplResultStatusChoiceKeys = {
    all: () => ["iplResultStatusChoices"],
};

export const nonLegacyIndicators = {
    all: () => ["nonLegacyIndicators"],
};

export const noteKeys = {
    all: () => ["notes"],
    active: () => ["noteId"],
    filter: ({ insightId }) => [...noteKeys.all(), "insightId", insightId],
};

export const opportunityFeedbackUsersKeys = {
    all: () => ["opportunityFeedbackUsers"],
    single: (opportunity_id: number, queryParams: string) => [
        ...opportunityFeedbackUsersKeys.all(),
        opportunity_id,
        queryParams,
    ],
};

export const opportunityInsightsKeys = {
    all: () => ["opportunityInsights"],
    single_indicator_group: (indicator_group_id: number, team_id: number) => [
        ...opportunityInsightsKeys.all(),
        indicator_group_id,
        team_id,
    ],
    single_report: (report_id: number, team_id: number) => [
        ...opportunityInsightsKeys.all(),
        report_id,
        team_id,
    ],
    team_insights: (team_id: number) => [...opportunityInsightsKeys.all(), team_id],
};

export const opportunityKeys = {
    all: () => ["opportunities"],
    single_opportunity: (id: number) => [...opportunityKeys.all(), id],
};

export const ownerKeys = {
    all: () => ["owners"],
    single: (id: number) => [...ownerKeys.all(), id],
    search: (query: string) => [...ownerKeys.all(), "search", query],
};

export const OwnerTypeKeys = {
    all: () => ["ownerTypes"],
};

export const profileKeys = {
    all: () => ["profiles"],
    single: (teamId: number) => [...profileKeys.all(), teamId],
};

export const queryKeys = {
    all: () => ["queries"],
    single: (id: number) => [...queryKeys.all(), id],
    search: (query: string) => [...queryKeys.all(), "search", query],
};

export const queryTypeKeys = {
    all: () => ["queryTypeKeys"],
};

export const reportKeys = {
    all: () => ["reports"],
    team_reports: (teamId: number) => [...reportKeys.all(), teamId],
    single_report: (reportId: number) => [...reportKeys.all(), reportId],
};

export const searchPlanKeys = {
    all: () => ["searchPlans"],
    single: (searchPlanId: number) => [...searchPlanKeys.all(), searchPlanId],
};

export const searchPlanFormKeys = {
    searchPlanOverview: (searchPlanId: number) => ["searchPlanOverview", searchPlanId],
    intelligenceScopeDetail: (intelligenceScopeId: number) => [
        "intelligenceScopeDetail",
        intelligenceScopeId,
    ],
};

export const sharedOpportunitiesKeys = {
    all: () => ["sharedOpportunities"],
    single_shared_opportunities: (id: number) => [...sharedOpportunitiesKeys.all(), id],
    report: ({
        reportId,
        opportunityIdsKey,
    }: {
        reportId: number;
        opportunityIdsKey?: string;
    }) => [
        ...sharedOpportunitiesKeys.all(),
        {
            type: "report",
            reportId,
            ...(opportunityIdsKey ? { opportunityIdsKey } : {}),
        },
    ],
    team: ({
        teamId,
        opportunityIdsKey,
    }: {
        teamId: number;
        opportunityIdsKey?: string;
    }) => [
        ...sharedOpportunitiesKeys.all(),
        { type: "team", teamId, ...(opportunityIdsKey ? { opportunityIdsKey } : {}) },
    ],
};

export const skinnyAPIKeys = {
    sidebarLayoutTeams: () => ["sidebar_layout", "teams"],
    sidebarLayoutSingleTeam: (id: number) => ["sidebar_layout", "teams", id],
    sidebarLayoutWhoami: () => ["sidebar_layout", "whoami"],
    sidebarLayoutProducts: () => ["sidebar_layout", "products"],
};

export const staffUserKeys = {
    all: () => ["staffUsers"],
    single: (id: number) => [...staffUserKeys.all(), id],
};

export const userProfileKeys = {
    all: () => ["userProfiles"],
    search: (teamId: number, query: string) => [
        ...userProfileKeys.all(),
        "team_id",
        teamId,
        "search",
        query,
    ],
};

export const StateKeys = {
    all: () => ["states"],
};

export const subscriptionKeys = {
    all: () => ["subscriptions"],
    team: (teamId: number) => [...subscriptionKeys.all(), teamId],
};

export const tableDataKeys = {
    all: () => ["tableData"],
    createKey: (feedType, queryParams) => [
        ...tableDataKeys.all(),
        feedType,
        queryParams,
    ],
};

export const tableFiltersKeys = {
    all: () => ["tableFilters"],
    allInsights: (team_id: number) => [
        ...tableFiltersKeys.all(),
        "allInsights",
        team_id,
    ],
    indicatorFeed: (indicator_group_id: number) => [
        ...tableFiltersKeys.all(),
        "indicatorFeed",
        indicator_group_id,
    ],
    singleReport: (report_id: number) => [
        ...tableFiltersKeys.all(),
        "singleReport",
        report_id,
    ],
    createKey: (feedType, id, secondaryId) => [
        ...tableFiltersKeys.all(),
        feedType,
        id,
        secondaryId,
    ],
};

export const tableItemKeys = {
    all: () => ["tableItems"],
    createKey: (feedType, queryParams) => [
        ...tableItemKeys.all(),
        feedType,
        queryParams,
    ],
};

export const tableOpportunityKeys = {
    all: () => ["tableOpportunities"],
    allInsightsOpportunities: (team_id: number) => [
        ...tableOpportunityKeys.all(),
        "allInsightsOpportunities",
        team_id,
    ],
    singleReportOpportunities: (report_id: number) => [
        ...tableOpportunityKeys.all(),
        "singleReportOpportunities",
        report_id,
    ],
    indicatorFeedOpportunities: (indicator_group_id: number) => [
        ...tableOpportunityKeys.all(),
        "indicatorFeedOpportunities",
        indicator_group_id,
    ],
    createKey: (queryParams) => [...tableOpportunityKeys.all(), queryParams],
};

export const teamCustomerTagKeys = {
    all: () => ["teamCustomerTags"],
    single: (id: number) => [...teamCustomerTagKeys.all(), id],
};

export const teamsKeys = {
    all: () => ["teams"],
    single: (id: number) => [...teamsKeys.all(), id],
};

export const timingStatusesKeys = {
    all: () => ["timingStatuses"],
};

export const userNameKeys = {
    all: () => ["userNames"],
    single: (id: number) => [...userNameKeys.all(), id],
};

export const viewDetailsKeys = {
    all: () => ["viewDetails"],
    single: (feedType: string, itemId: number) => [
        ...viewDetailsKeys.all(),
        feedType,
        itemId,
    ],
};

export const warningLabelTypeKeys = {
    all: () => ["warningLabelTypeKeys"],
};
