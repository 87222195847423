import { useQuery } from "@tanstack/react-query";

import { FEED_TYPES } from "constants/feedTypes";
import { nautilusGetRequest } from "network";
import { viewDetailsKeys } from "reactQuery/keys";

const API_PATH = {
    [FEED_TYPES.LW_ALL_INSIGHTS]: "ipl_insight_details",
    [FEED_TYPES.LW_ALL_RESULTS]: "ipl_result_details",
    [FEED_TYPES.LW_MY_ACTIONED]: "ipl_result_details",
    [FEED_TYPES.LW_MY_QUEUE]: "ipl_result_details",
    [FEED_TYPES.LW_READY_FOR_QC]: "ipl_result_details",
};

async function getDetailsData({
    feedType,
    itemId,
}: {
    feedType: string;
    itemId: number;
}) {
    const { data } = await nautilusGetRequest(
        `/api/target_reports/${API_PATH[feedType]}/${itemId}`
    );
    return data;
}

export function useGetDetailsData({
    feedType,
    itemId,
    enabled = true,
}: {
    feedType: string;
    itemId: number;
    enabled?: boolean;
}) {
    return useQuery({
        queryKey: viewDetailsKeys.single(feedType, itemId),
        queryFn: () => getDetailsData({ feedType, itemId }),
        enabled,
        refetchOnWindowFocus: false,
    });
}
