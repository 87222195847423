import { Form, Select } from "antd";
import { useEffect, useState } from "react";

import { useSearchDebounced } from "components/helpers/debounce";
import { IndicatorTag } from "reactQuery/hooks/apiTypes";
import { useGetIndicatorTagsByQuery } from "reactQuery/hooks/useIndicatorTags";

import { ConstraintRecordProps } from "../columnInfo";
import { validateMultiSelectWithIncludeNull } from "../validationHelper";

const IndicatorTagsSelect = ({
    constraintName,
    constraintId,
}: ConstraintRecordProps) => {
    const [searchQuery, setSearchQuery] = useState("");
    const queryMinChars = 1;
    const enterQueryPrompt = `Enter at least ${queryMinChars} character to search for an indicator tag`;

    const form = Form.useFormInstance();
    const indicatorValue = Form.useWatch("indicator", form);
    const indicatorId = indicatorValue?.[2] ?? undefined;

    const indicatorTagsQueryEnabled =
        searchQuery.length >= queryMinChars || Boolean(indicatorId);
    const indicatorTagsQuery = useGetIndicatorTagsByQuery(
        searchQuery,
        indicatorId,
        indicatorTagsQueryEnabled
    );
    const debounceSearch = useSearchDebounced(setSearchQuery);

    useEffect(() => {
        if (indicatorId) {
            setSearchQuery("");
        }
    }, [indicatorId]);

    return (
        <Form.Item
            style={{ margin: 0 }}
            name={[constraintName, `constraint_id_${constraintId}`, "indicator_tags"]}
            preserve={false}
            rules={[
                ({ getFieldValue }) =>
                    validateMultiSelectWithIncludeNull(
                        constraintName,
                        constraintId,
                        getFieldValue,
                        "Please select one or more indicator tags"
                    ),
            ]}
        >
            <Select
                placeholder="Select one or more indicator tags"
                showSearch
                filterOption={false}
                allowClear={true}
                mode="multiple"
                style={{ width: "100%" }}
                onSearch={(value) => {
                    debounceSearch(value);
                }}
                onClear={() => {
                    debounceSearch("");
                }}
            >
                {!indicatorTagsQueryEnabled ? (
                    <Select.Option value="disabled" disabled>
                        {enterQueryPrompt}
                    </Select.Option>
                ) : indicatorTagsQuery.isSuccess ? (
                    (indicatorTagsQuery.data || []).map(
                        (indicatorTag: IndicatorTag) => {
                            return (
                                <Select.Option
                                    key={indicatorTag.id}
                                    value={indicatorTag.id}
                                    title={indicatorTag.value}
                                >
                                    {indicatorTag.value}
                                </Select.Option>
                            );
                        }
                    )
                ) : indicatorTagsQuery.isError ? (
                    <Select.Option value="disabled" disabled>
                        There was a problem loading indicator tag options
                    </Select.Option>
                ) : (
                    <Select.Option value="disabled" disabled>
                        Loading indicator tags...
                    </Select.Option>
                )}
            </Select>
        </Form.Item>
    );
};

export default IndicatorTagsSelect;
