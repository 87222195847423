import Grid from "@material-ui/core/Grid";
import { useState } from "react";

import StyledButton from "components/atoms/StyledButton";
import { openNewInternalLink } from "constants/navigation";
import { OpportunityValueRead, OpportunityRead } from "reactQuery/hooks/apiTypes";

import { OpportunityValueModal } from "../Modal";

const OpportunityValueDisplay = ({
    opportunityValue,
    isStaff,
    styles,
}: {
    opportunityValue: OpportunityValueRead;
    isStaff: boolean;
    styles: any;
}) => {
    const {
        source_opportunity_value: sourceOppValue,
        source_opportunity_value_currency: sourceOppCurrency,
    } = opportunityValue;
    const sourceOpportunityText = sourceOppValue
        ? `$${sourceOppValue.toLocaleString()} ${sourceOppCurrency}`
        : "Unknown";

    const {
        customer_opportunity_value: customerOppValue,
        customer_opportunity_value_currency: customerOppCurrency,
    } = opportunityValue;
    const customerOpportunityText = customerOppValue
        ? `$${customerOppValue.toLocaleString()} ${customerOppCurrency}`
        : "Unknown";

    if (isStaff) {
        return (
            <>
                <span
                    className={styles.fiscalValue}
                    style={{ paddingRight: "5px", paddingLeft: "42px" }}
                    data-testid="staff-source-opp-value"
                >
                    Source:{" "}
                    <span className={styles.fiscalValue} style={{ fontWeight: "bold" }}>
                        {sourceOpportunityText}
                    </span>
                </span>
                <span
                    className={styles.fiscalValue}
                    data-testid="staff-customer-opp-value"
                >
                    Customer:{" "}
                    <span className={styles.fiscalValue} style={{ fontWeight: "bold" }}>
                        {customerOpportunityText}
                    </span>
                </span>
            </>
        );
    } else {
        return (
            <span
                className={styles.fiscalValue}
                style={{ fontWeight: "bold", paddingLeft: "42px" }}
                data-testid="external-opp-value"
            >
                {customerOppValue || customerOppValue === 0
                    ? customerOpportunityText
                    : sourceOpportunityText}
            </span>
        );
    }
};

export const OpportunityValueFactoid = ({
    opportunity,
    isStaff,
    styles,
    isEditModeActive,
}: {
    opportunity: OpportunityRead;
    isStaff: boolean;
    styles: any;
    isEditModeActive: boolean;
}) => {
    const [editModalOpen, setEditModalOpen] = useState(false);
    const opportunityValue = opportunity?.opportunity_value;

    const openOpportunityAdminPage = (opportunityId: number) => {
        openNewInternalLink(
            `/admin/target_reports/opportunity/${opportunityId}/change/`,
            false
        );
    };

    return opportunityValue ? (
        <>
            <Grid
                container
                style={{
                    alignItems: "left",
                    marginTop: "20px",
                    marginBottom: "10px",
                }}
            >
                <Grid item xs={2}>
                    <span className={styles.fiscalLabel}>Opportunity Value:</span>
                </Grid>
                <Grid item xs={9}>
                    <OpportunityValueDisplay
                        opportunityValue={opportunityValue}
                        isStaff={isStaff}
                        styles={styles}
                    />
                </Grid>
                {isEditModeActive || !isStaff ? (
                    <Grid item xs={4}>
                        <StyledButton
                            data-testid="edit customer value"
                            handleClick={() =>
                                isStaff
                                    ? openOpportunityAdminPage(opportunity?.id)
                                    : setEditModalOpen(true)
                            }
                            variant="edit-secondary"
                        >
                            Edit
                        </StyledButton>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>

            {!isStaff ? (
                <OpportunityValueModal
                    opportunity={opportunity}
                    editModalOpen={editModalOpen}
                    setEditModalOpen={setEditModalOpen}
                />
            ) : null}
        </>
    ) : null;
};
